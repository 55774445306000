import React from "react"
import { graphql } from "gatsby"

import {
  Layout,
  useLayoutStyles,
  usePageStyles,
} from "@components/layout/layout"
import MDXRenderer from "@components/layout/section/common/page/mdxRender"

/*
children: 
location: {hash:, host:, hostname:, href, key, orgin, pathname, port, protocol, search, state:{key:}}
navigate: ƒ navigate(to, options)
pageContext: {slug: }
pageResources: {json: {…}, page: {…}, staticQueryResults: {…}, component: ƒ}
params: {}
path: "/about"
pageContext: {slug: "/about"} //Based off gatsby-node create page
uri: "/about"
*/
export default function TemplatePage({
  data,
  pageContext,
  location,
  ...props
}) {
  const { node, blogPosts } = data

  const classes = useLayoutStyles()
  const classesPage = usePageStyles()

  if (
    pageContext.dynamicTitle !== null &&
    typeof pageContext.dynamicTitle !== "undefined"
  ) {
    if (pageContext.dynamicTitle.length > 0) {
      if (
        node.context.title === null ||
        typeof node.context.title === "undefined"
      ) {
        node.context.title = ""
      }
      node.context.title += " - " + pageContext.dynamicTitle
    }
  }

  return (
    <Layout
      pageNode={node}
      location={location}
      pageContext={pageContext}
      {...props}
    >
      <MDXRenderer
        pageNode={node}
        posts={blogPosts}
        pageContext={pageContext}
        location={location}
        classes={classes}
        classesPage={classesPage}
      />
    </Layout>
  )
}

export const query = graphql`
  query($pageID: String!, $postYear: Int!) {
    node: threeMysticApesPage(id: { eq: $pageID }) {
      slug
      context: page {
        created
        startDate
        slug
        ariaLabel
        button
        description
        keywords
        meta {
          content
          name
          property
        }
        pageStyle {
          name
          style
        }
        title
      }
    }
    blogPosts: allThreeMysticApesPost(
      filter: { showNode: { eq: true }, postYear: { eq: $postYear } }
      sort: { fields: post___startDate, order: DESC }
    ) {
      edges {
        node {
          post {
            title
            excerpt
            mysticApes {
              name
            }
            seriesData {
              series {
                name
                display
              }
              part
            }
            created
            startDate
            hero {
              publicURL
            }
            gridSmall {
              publicURL
            }
            gridLarge {
              publicURL
            }
          }
          slug
        }
      }
    }
  }
`
